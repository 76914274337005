.container {
  background: linear-gradient(180deg, #17c3b2 55%, #fff7e7);
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 100px;
  position: relative;
  z-index: 99;
  width: 100%;
}

.container .title {
  color: #fff7e7;
}

.container .links .navLink {
  color: #fff7e7;
  text-align: center;
}
.container .links .navLink:hover {
  color: #454545;
}

.container .links .navLink.active {
  color: #454545;
  text-decoration: underline;
}

.container .navLinkDivider {
  background-color: #777777;
  height: 35px;
}

.wrapper .footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 45%;
  z-index: -1;
}
