.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper hr {
  padding: 5px 0;
  width: 230px;
}
