body,
html {
  margin: 0;
  padding: 0;
}

.container {
  margin: 0 auto auto auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.container .header {
  margin-bottom: 50px;
}

.container .headerText {
  text-align: center;
  margin-bottom: 10px;
}

.container .switch {
  margin-top: 10px;
  margin-bottom: 10px;
}

.container .loader {
  margin-top: 25px;
}

.container .requestResponse {
  text-align: center;
  margin-top: 5px;
  white-space: pre-line;
}
