div .container {
  cursor: pointer;
  /* fixed height to stop expansion when hovering */
  height: 52px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color, rgba(255, 255, 255, 0.9));
}

.container .cardContent {
  /* fixed height to stop expansion when hovering */
  height: 48px;
}

.container .cardContent:last-child {
  padding: 5px;
}
