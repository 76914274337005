.container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 30px;
}

.container .selector {
  width: 200px;
}
