div .card {
  background-color: rgba(255, 247, 231, 0.6); /* 60% opacity */
  border-radius: 40px;
  height: var(--size, 375px);
}

.card .cardContents {
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 0 15px;
}
