.emailAlertContainer {
  height: 55px;
  width: 60px;
  z-index: 99;
  display: flex;
  align-items: center;
}
.emailAlertContainer .emailAlert {
  height: 25px;
  border-radius: 10px;
  align-items: center;
  position: absolute;
}

.emailImage {
  cursor: pointer;
}
