.headshot {
  width: 190px;
  height: 250px;
  border-radius: 200px;
  margin: auto;
}
.body {
  margin: auto 15px;
  text-align: center;
}
.body .title {
  font-weight: bold;
  text-decoration: underline;
}
.linksContainer {
  width: 60px;
  padding: 0 65px;
  margin: auto;
}

.linksContainer .emailImage {
  cursor: pointer;
}

.linksContainer .emailAlertContainer {
  height: 55px;
  width: 60px;
  z-index: 99;
  display: flex;
  align-items: center;
}

.linksContainer .emailAlertContainer .emailAlert {
  height: 25px;
  border-radius: 10px;
  align-items: center;
  position: absolute;
}
