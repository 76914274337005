.container {
  height: 70vh;
  overflow: auto;
}
.list {
  padding-top: 0;
}
.list .listItem {
  padding-top: 5px;
  padding-bottom: 5px;
}

.list::-webkit-scrollbar {
  width: 7px;
}
.list::-webkit-scrollbar-track {
  background-color: rgba(119, 119, 119, 0.2);
  border-radius: 8px;
}
.list::-webkit-scrollbar-thumb {
  background-color: rgba(119, 119, 119, 0.7);
  border-radius: 8px;
}

ul.nonDraggableList {
  max-height: calc(100vh - 130px);
  overflow-y: auto;
  padding: 0 0 10px 0;
  scrollbar-gutter: stable;
  min-width: 225px;
}
.nonDraggableList .listItem {
  padding: 5px 0;
}

.nonDraggableList::-webkit-scrollbar {
  width: 7px;
}
.nonDraggableList::-webkit-scrollbar-track {
  background-color: rgba(119, 119, 119, 0.2);
  border-radius: 8px;
}
.nonDraggableList::-webkit-scrollbar-thumb {
  background-color: rgba(119, 119, 119, 0.7);
  border-radius: 8px;
}
