.container {
  display: flex;
  min-width: 300px;
}
.container .horizontalTitle {
  text-align: center;
  align-self: center;
  max-width: 120px;
  margin-right: 20px;
  margin-bottom: 30px;
}
