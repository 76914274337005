.container {
  position: relative;
  display: flex;
}

.container div div svg g {
  cursor: pointer;
}

.container .message {
  align-self: center;
  text-align: center;
}

.container .message a {
  /* color: red; */
}
