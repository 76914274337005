.container {
  z-index: 2;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container .formContainer {
  box-shadow: 1px 3px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 15px 25px;
  max-height: 72vh;
  width: 275px;
  display: flex;
  flex-direction: column;
  background-color: white;
}
