.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}
.container .subContainer {
  max-height: calc(100vh - 174px);
  display: flex;
  align-items: center;
}

.container .transactionListContainer {
  display: flex;
  width: 300px;
}
