div .container {
  margin: 0 5px;
  background-color: var(--backgroundColor, red);
  height: var(--height, 100%);
  position: relative;
  text-align: center;
  min-width: 255px;
}
div .container .cardHeader {
  padding: 5px;
}
div .container .cardContent {
  height: calc(100% - 90px);
  overflow: auto;
  padding: 0 5px;
  scrollbar-gutter: stable;
}

div .container .cardContent .subcategoriesContainer {
  /* height: 100%; */
}

div .container .button {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.cardContent::-webkit-scrollbar {
  width: 7px;
}
.cardContent::-webkit-scrollbar-track {
  background-color: rgba(119, 119, 119, 0.25);
  border-radius: 8px;
}
.cardContent::-webkit-scrollbar-thumb {
  background-color: rgba(119, 119, 119, 0.7);
  border-radius: 8px;
}
