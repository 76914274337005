.container {
  max-height: calc(100vh - 85px);
  min-width: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  scrollbar-gutter: stable;
}

.container::-webkit-scrollbar {
  width: 7px;
}
.container::-webkit-scrollbar-track {
  background-color: rgba(119, 119, 119, 0.2);
  border-radius: 8px;
}
.container::-webkit-scrollbar-thumb {
  background-color: rgba(119, 119, 119, 0.7);
  border-radius: 8px;
}
