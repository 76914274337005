.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  margin: auto;
}
.container .subContainer {
  display: flex;
}
.container .subContainer .userInfo {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-right: 20px;
}

.container .loader {
  margin-top: 15px;
}
