div .transactionCardContent {
  display: flex;
  width: 200px;
  padding: 5px;
  align-items: center;
  background-color: var(--backgroundColor, red);
}

div .transactionCardContent:last-child {
  padding-bottom: 5px; /* Override the padding */
}

.transactionCardContent .spacer {
  min-width: 30px;
}
