.container {
  z-index: 2;
  display: flex;
  justify-content: space-around;
  width: 80vw;
}
.container .subContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.container .subContainer .listContainer {
  width: 415px;
  padding: 10px;
  border-radius: 60px;
  background-color: rgba(255, 247, 231, 0.6); /* 60% opacity */
}

.listContainer .stackItem {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.subContainer .listContainer .stackItem .activeIcon {
  font-size: 3rem;
  color: var(--hoverColor, red);
}

.listContainer .stackItem .activeText {
  font-weight: bold;
  color: var(--hoverColor, red);
}
.listContainer .stackItem svg:first-child {
  color: #454545;
  margin: 0 25px 0 25px;
  font-size: 2.5rem;
}
.listContainer .stackItem h5 {
  color: #454545;
}

.container .subContainer .title {
  border: 1px #777777 solid;
  box-shadow: 2px 2px 3px #fe6d73, -1px -1px 3px #227c9d;
  border-radius: 25px;
  padding: 10px;
  margin-bottom: 20px;
}
