.container {
  max-height: 26px;
  border-bottom: 1px solid rgba(119, 119, 119, 0.5);
  /* margin-bottom: 15px; */
}

.container .list {
  display: flex;
  height: 26px;
  /* gap: 1rem; */
}

a {
  text-decoration: none;
  color: var(--color-primary-400);
}

a:hover,
a.active {
  color: var(--color-primary-800);
  text-decoration: underline;
}
