.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.container .pie {
  margin-bottom: auto;
}
.container .pie *:focus {
  outline: none;
}
.container .pie :first-child {
  cursor: pointer;
}

.container .pie :first-child:focus {
  cursor: default;
}
.container .infoContainer {
  height: 80px;
  width: 340px;
  padding: 20px;
  border-radius: 40px;
  background-color: rgba(255, 247, 231, 0.6); /* 60% opacity */
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
}

.container .infoContainerPlaceholder {
  opacity: 0;
}
