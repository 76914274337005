.container {
  margin-bottom: 40px;
}

.selector {
  position: relative;
  z-index: 2;
}
.selector h6 {
  position: relative;
  border: 2px #777777 solid;
  border-radius: 20px;
  background-color: #fff7e7;
  padding: 5px;
  width: 275px;
  z-index: 3;
  cursor: pointer;
  text-align: center;
}
.selector div {
  position: absolute;
  top: -1.3px;
  left: -1px;
  height: 46px;
  width: 100%;
  border-radius: 20px;
  background-color: #227c9d;
  box-shadow: -1px -2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.selector div:last-child {
  top: 1.3px;
  left: 1.3px;
  background-color: #fe6d73;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.container .title {
  cursor: pointer;
  border: 1px #777777 solid;
  border-radius: 20px;
  padding: 6px;
}
.container .title:hover {
  box-shadow: 1px 1px 2px #fe6d73, -1px -1px 1px #227c9d;
}
.container .title.active {
  box-shadow: 2px 2px 4px #fe6d73, -1px -1px 4px #227c9d;
}
