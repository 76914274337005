.container {
  display: flex;
  justify-content: space-around;
}

.container .newTransactionsContainer {
  height: 90vh;
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}
.leftDivider {
  height: calc(100vh - 71.1px);
  border-left: 2px rgba(119, 119, 119, 0.5) solid;
}
