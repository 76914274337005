.container {
  box-shadow: 1px 3px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px 20px;
  width: 650px;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 2;
  margin: auto;
}
